import * as React from 'react'
import { Box } from '@mui/material'
import { DropzoneWrapper } from './DropzoneStyle'
import FileUploaderMultiple from '@/views/forms/file-uploader/FileUploaderMultiple'
import { useMutation } from 'react-query'
import CreateSampleWizard from '.'
import {
  createSample,
  Patients,
  Sample,
  ServerName,
  TaskStatus,
  TaskWesCnvBatchDto,
  upload
} from '@/@core/services/app.service'
import { getCode } from '@/@core/layouts/utils'
import PositionedSnackbar from '@/@core/components/message'
import { useTranslation } from 'react-i18next'
import { calculateBirthDate, unique } from '@/@core/utils/utils'
import { NObject } from '@/@core/components/filter-variants'
import _ from 'lodash'

interface Props {
  fn: any
  setHasTermsOpen?: any
}

interface Samples {
  progress: number
  cnvProgress: number
  file: File
  prefix: string
  cnvPrefix?: string
  fileSize: number
  cnvFileSize?: number
  sampleValue: any
  isCnv?: boolean
  isCreate?: boolean
}

const CreateSample = React.forwardRef((props: Props, ref) => {
  const [samples, setSamples] = React.useState<Samples[]>([])
  const createRef = React.useRef<any>()
  const messageRef = React.useRef<any>()
  const [confirm, setConfirm] = React.useState(false)
  const [confirmOpen, setConfirmOpen] = React.useState(false)
  const { t } = useTranslation()
  const [Key, setKey] = React.useState(0)
  const defaultValues = {
    sample: {
      sampleName: '',
      sampleSource: 'blood',
      type: 'WES',
      genomeBuild: 'hg19' as 'hg19' | 'hg38',
      projectId: null,
      genePanel: null,
      targetRegionId: null,
      prefix: '',
      fileSize: 0,
      hasCnv: false,
      cnvFile: '',
      cnvName: '',
      isFq: false,
      fqFiles: []
    },
    clinical: {
      termList: [],
      clinicalInformation: ''
    },
    patient: {
      patientName: '',
      infirmaryName: '',
      departmentName: '',
      caseNumber: '',
      birthday: null,
      doctor: '',
      gender: 'unknown' as 'male' | 'female' | 'unknown',
      race: null,
      acceptDate: null,
      tooptip: [],
      status: true,
      hasSv: false,
      svFile: '',
      svName: '',
      hasCnv: false,
      cnvFile: '',
      cnvName: '',
      isFq: false,
      fqFiles: []
    }
  }

  const handleUpload = async (files: File[]) => {
    const fileData = files.map((items) => {
      const temp =
        items.name.includes('R1') || items.name.includes('R2') ? 'R' : '_'
      const nameArr = items.name.split('.')
      const suffix = nameArr[nameArr.length - 1]
      const tempR1 = items.name.split(`${temp}1`)
      const tempR2 = items.name.split(`${temp}2`)

      if (tempR1.length === 2) {
        // R1
        return {
          file: items,
          group: `${tempR1[0]}${temp}${tempR1[1]}`
        }
      } else if (tempR2.length === 2) {
        // R2
        return {
          file: items,
          group: `${tempR2[0]}${temp}${tempR2[1]}`
        }
      } else {
        // vcf...
        return {
          file: items,
          group: `${items.name.split('_')[0]}${suffix}`
        }
      }
    })

    console.log(files, fileData)

    const groupedData: NObject = fileData.reduce((groups: any, item) => {
      const group = item.group

      if (!groups[group]) {
        groups[group] = []
      }

      groups[group].push(item)

      return groups
    }, {})

    const arr = Object.getOwnPropertyNames(groupedData).map((key) => {
      const item = groupedData[key] as {
        gropu: string
        file: File
      }[]

      if (item && item.length === 1) {
        const file = item[0].file

        const fd = new FormData()
        const fileName = file.name.replace(/\s+/g, '')
        let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

        if (fileName.includes('.cnv')) {
          baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
        }

        fd.append('file', file, encodeURI(fileName))

        return {
          file: file,
          progress: 0,
          cnvProgress: 0,
          prefix: '',
          cnvPrefix: '',
          fileSize: 0,
          cnvFileSize: 0,
          sampleValue: {
            ...defaultValues,
            sample: {
              ...defaultValues.sample,
              sampleName: baseName
            }
          }
        }
      } else {
        const [first, ...other] = item
        const file = first.file
        const otherFile = other.map((item) => {
          return {
            fileName: item.file.name,
            file: item.file,
            process: 0
          }
        })

        console.log(first, other)

        const fd = new FormData()
        const fileName = file.name.replace(/\s+/g, '')
        let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

        if (fileName.includes('.cnv')) {
          baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
        }

        fd.append('file', file, encodeURI(fileName))

        return {
          file: file,
          progress: 0,
          cnvProgress: 0,
          prefix: '',
          cnvPrefix: '',
          fileSize: 0,
          cnvFileSize: 0,
          sampleValue: {
            ...defaultValues,
            sample: {
              ...defaultValues.sample,
              sampleName: baseName
            },
            patient: {
              ...defaultValues.patient,
              fqFiles: otherFile
            }
          }
        }
      }
    })

    console.log(arr)

    // const data = files.map((file, index: number) => {
    //   const fd = new FormData()
    //   const fileName = file.name.replace(/\s+/g, '')
    //   let baseName = fileName.substring(0, fileName.lastIndexOf('.'))

    //   if (fileName.includes('.cnv')) {
    //     baseName = fileName.substring(0, fileName.lastIndexOf('.cnv'))
    //   }

    //   fd.append('file', file, encodeURI(fileName))

    //   return {
    //     file: file,
    //     progress: 0,
    //     cnvProgress: 0,
    //     prefix: '',
    //     cnvPrefix: '',
    //     fileSize: 0,
    //     cnvFileSize: 0,
    //     sampleValue: {
    //       ...defaultValues,
    //       sample: {
    //         ...defaultValues.sample,
    //         sampleName: baseName
    //       }
    //     }
    //   }
    // })

    setSamples(arr)

    setTimeout(() => {
      createRef.current &&
        createRef.current.handleUpload(
          arr.map((item) => item.file),
          arr
        )

      console.log('createRef.current', createRef.current)
    }, 100)
  }

  const getUploadProgress2 = (
    value: any,
    type: string,
    index: number,
    Samples: any[]
  ) => {
    let sampleList = samples

    sampleList = sampleList.map((sample: any, i) => {
      if (i === index) {
        if (type === 'fileSize') {
          sample.fileSize = value
        }

        if (type === 'progress') {
          sample.progress = value
        }

        if (type === 'prefix') {
          sample.prefix = value
        }
      }

      if (sample.hasCnv) {
        samples.forEach((item) => {
          if (
            item.sampleValue.sample.sampleName ===
              sample.sampleValue.sample.sampleName &&
            item.isCnv
          ) {
            if (type === 'fileSize') {
              sample.cnvFileSize = item.fileSize
            }

            if (type === 'progress') {
              sample.cnvProgress = item.progress
            }

            if (type === 'prefix') {
              sample.cnvPrefix = item.prefix
            }
          }
        })
      }

      return sample
    })

    setKey(Math.random())
  }

  const getUploadProgress = (
    value: any,
    type: string,
    index: number,
    Samples: any[]
  ) => {
    const data = Samples.map((sample, i) => {
      if (i === index) {
        sample[type] = value

        if (type === 'fileSize') {
          sample.fileSize = value
        }

        if (type === 'progress') {
          sample.progress = value
        }

        if (type === 'prefix') {
          sample.prefix = value
        }
      }

      if (sample.hasCnv) {
        Samples.forEach((item) => {
          if (
            item.sampleValue.sample.sampleName ===
              sample.sampleValue.sample.sampleName &&
            item.isCnv
          ) {
            if (type === 'fileSize') {
              sample.cnvFileSize = item.fileSize
            }

            if (type === 'progress') {
              sample.cnvProgress = item.progress
            }

            if (type === 'prefix') {
              sample.cnvPrefix = item.prefix
            }
          }
        })
      }

      return sample
    })

    setSamples(data)
  }

  const handleSave = async (sample: any, cnvBatchId: any) => {
    const formField = sample.sampleValue
    const prefix = sample.prefix.split('?')[0]
    const cnvPrefix = sample.cnvPrefix
    const file = sample.file

    console.log(formField)

    console.log(sample)

    const patientParams = {
      ...formField.patient,
      arrivalDate: formField.patient.acceptDate,
      receiveDate: formField.patient.acceptDate,
      dateOfBirth: formField.patient.birthday
        ? calculateBirthDate(Number(formField.patient.birthday))
        : null,
      birthday: formField.patient.birthday
        ? calculateBirthDate(Number(formField.patient.birthday))
        : null,
      physicianName: formField.patient.doctor,
      patientName: formField.patient.patientName,
      infirmaryName: formField.patient.infirmaryName,
      departmentName: formField.patient.departmentName,
      caseNumber: formField.patient.caseNumber,
      gender: formField.patient.gender,
      ethnicity: formField.patient.race,
      sampleType: formField.sample.sampleSource
    }

    delete patientParams.fqFiles

    delete patientParams.isFq
    const clinicalParams = {}
    const user = JSON.parse(localStorage.getItem('user') as string)
    const fastqFiles = _.cloneDeep(formField.patient.fqFiles) as {
      fileName: string
      process: number
      fileSize: number
    }[]
    const vcfFile = decodeURI(prefix.split('/')[prefix.split('/').length - 1])
    const fileType = vcfFile
    const sampleType =
      fileType.includes('.vcf') || fileType.includes('.vcf.gz')
        ? 'vcf'
        : fileType.includes('.gtc')
        ? 'gtc'
        : fileType.includes('.fq.gz') ||
          fileType.includes('.fastq.gz') ||
          fastqFiles.length
        ? 'fastq'
        : fileType.includes('.naf')
        ? 'naf'
        : 'vcf'

    console.log('sampleType', sampleType)

    const combinations = []

    if (sampleType === 'fastq' || sampleType === 'naf') {
      if (
        formField.patient &&
        formField.patient.fqFiles &&
        Array.isArray(formField.patient.fqFiles) &&
        formField.patient.fqFiles.length
      ) {
        let fqFiles = fastqFiles

        console.log(vcfFile, file)

        fqFiles.push({
          fileName: vcfFile ? vcfFile : file.name,
          process: 100,
          fileSize: sample.fileSize
            ? Number(sample.fileSize.toFixed(2))
            : Number((file.size / (1024 * 1024)).toFixed(2))
        })

        console.log(fqFiles)

        fqFiles = unique(fqFiles, 'fileName')

        for (let i = 0; i < fqFiles.length - 1; i++) {
          for (let j = i + 1; j < fqFiles.length; j++) {
            const temp =
              fqFiles[i].fileName.includes('R1') ||
              fqFiles[i].fileName.includes('R2')
                ? 'R'
                : '_'
            let str = ''
            if (fqFiles[i].fileName.includes(`${temp}1`))
              str = fqFiles[i].fileName.split(`${temp}1`)[0]
            else if (fqFiles[i].fileName.includes(`${temp}2`))
              str = fqFiles[i].fileName.split(`${temp}2`)[0]

            console.log(
              `${str}`,
              fqFiles[j].fileName,
              fqFiles[j].fileName.includes(`${str}`)
            )

            if (
              fqFiles[j].fileName.includes(`${str}`) &&
              fqFiles[i].fileName.includes(`${temp}1`)
            )
              combinations.push({
                fastq_1: fqFiles[i].fileName,
                fastq_2: fqFiles[j].fileName
              })
            else if (
              fqFiles[j].fileName.includes(`${str}`) &&
              fqFiles[i].fileName.includes(`${temp}2`)
            )
              combinations.push({
                fastq_1: fqFiles[j].fileName,
                fastq_2: fqFiles[i].fileName
              })
          }
        }
      }
    }

    console.log(combinations)

    const params = {
      name: formField.sample.sampleName,
      projectId: formField.sample.projectId,
      genomeBuild: formField.sample.genomeBuild,
      prefix: getCode(),
      folderPath: sampleType === 'fastq' || sampleType === 'naf' ? '' : prefix,
      gender: formField.patient.gender as 'female' | 'male',
      createdAt: new Date(),
      status: (formField.patient.status
        ? sampleType === 'fastq' || sampleType === 'naf'
          ? 'fastq_queuing'
          : 'vcf_queueing'
        : 'created') as 'vcf_queueing' | 'created',
      geneSetId: formField.sample.targetRegionId,
      genePanelId: formField.sample.genePanel,
      clinicalInformation: formField.clinical.clinicalInformation,
      terms: JSON.stringify(formField.clinical.termList),
      isDelete: 0 as 0 | 1,
      vcfType:
        sampleType === 'fastq' || sampleType === 'naf'
          ? 'wes'
          : (formField.sample.type as
              | 'wgs'
              | 'wes'
              | 'rna'
              | 'microarray'
              | 'gene_panel'),
      patientParams,
      userId: user.id,
      fileSize:
        sampleType === 'fastq' || sampleType === 'naf'
          ? `${fastqFiles
              .reduce(
                (accumulator, currentValue) =>
                  accumulator + currentValue.fileSize,
                0
              )
              .toFixed(2)}`
          : `${sample.fileSize.toFixed(2)}`,
      hasCnv: Boolean(sample.hasCnv || formField.patient.hasCnv),
      hasSv: Boolean(formField.patient.hasSv),
      svFile: decodeURI(formField.patient.svFile),
      cnvFile: cnvPrefix
        ? decodeURI(cnvPrefix.split('/')[cnvPrefix.split('/').length - 1])
        : formField.patient.cnvFile,
      vcfFile: vcfFile,
      sampleType,
      fastqFiles: combinations,
      hasFastTrack: sampleType === 'fastq' || sampleType === 'naf',
      cnvBatchTaskId: cnvBatchId
    }

    if (!params.name || !params.genomeBuild) return

    console.log(params, fastqFiles)

    return await handleSaveNewSam.mutateAsync(params)
  }

  const handleSaveNewSam = useMutation({
    mutationFn: async (newSamoleData: Sample) =>
      await createSample(newSamoleData),
    onSuccess: (data) => {
      //post返回的结果
      messageRef?.current?.handleClick(t('New successful'), 'success')
    }
  })

  // const uploadStatus = React.useMemo(() => {
  //   const flag = samples.every(
  //     (items) =>
  //       items.prefix ||
  //       items.cnvPrefix ||
  //       (items.sampleValue.patient.fqFiles &&
  //         items.sampleValue.patient.fqFiles.length &&
  //         items.sampleValue.patient.fqFiles.every(
  //           (i: any) => i && i.process === 100
  //         ))
  //   )

  //   console.log(flag, samples)

  //   return flag
  // }, [samples])

  const save = async () => {
    const flag = samples.every(
      (items) =>
        items.prefix ||
        items.cnvPrefix ||
        (items.sampleValue.patient.fqFiles &&
          items.sampleValue.patient.fqFiles.length &&
          items.sampleValue.patient.fqFiles.every(
            (i: any) => i && i.process === 100
          ))
    )

    console.log(flag, samples)
    if (!flag)
      return messageRef?.current?.handleClick('请等待样本上传完成', 'warning')

    saveSamples()

    // if (samples) {
    //   if (props.setHasTermsOpen) {
    //     const hasNotTermsSamples = samples.filter(
    //       (sample) =>
    //         !(
    //           sample.sampleValue.clinical.termList &&
    //           sample.sampleValue.clinical.termList.length
    //         )
    //     )

    //     if (hasNotTermsSamples && hasNotTermsSamples.length) {
    //       console.log(hasNotTermsSamples)

    //       props.setHasTermsOpen && props.setHasTermsOpen(true)
    //     } else saveSamples()
    //   } else saveSamples()
    // }
  }

  const saveSamples = async () => {
    let cnvBatchId: any = null

    if (createRef.current) {
      const isCnvBatch = createRef.current.isCnvBatch

      if (isCnvBatch && createRef.current.createCnvBatchs) {
        const user = JSON.parse(localStorage.getItem('user') || '{}')
        const params: TaskWesCnvBatchDto = {
          status: TaskStatus.QUEUEING,
          userId: user.id,
          serverName: ServerName.LOCAL,
          createdAt: new Date(),
          updatedAt: new Date()
        }

        console.log(params)

        const data = await createRef.current.createCnvBatchs(params)

        if (data) {
          cnvBatchId = data.id
        }
      }
    }

    await Promise.all(
      samples
        .filter((item) => !item.isCnv)
        .map(async (sample) => {
          await handleSave(sample, cnvBatchId)
        })
    )

    setTimeout(() => {
      props.fn && props.fn()
    }, 0)
  }

  React.useImperativeHandle(ref, () => {
    return {
      save,
      confirm,
      saveSamples
    }
  })

  return (
    <Box>
      {samples.length ? (
        <CreateSampleWizard
          getUploadProgress={getUploadProgress}
          getUploadProgress2={getUploadProgress2}
          samples={samples}
          setSamples={setSamples}
          ref={createRef}
          handleUpload={handleUpload}
        />
      ) : (
        <FileUploaderMultiple
          disableList={true}
          accept=".vcf, .vcf.gz, .gtc, .fq.gz, .fastq.gz,.naf"
          fn={handleUpload as any}
          key={'upload1'}
        />
      )}

      <PositionedSnackbar ref={messageRef} />
    </Box>
  )
})

export default CreateSample
